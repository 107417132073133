import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/greg/Desktop/projects/portfolio/src/components/page.js";
import Social from '../components/social';
import SEO from '../components/seo';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Home" mdxType="SEO" />
    <p>{`Hey. I'm Greg.`}</p>
    <p>{`I work for a `}<a parentName="p" {...{
        "href": "/vic"
      }}>{`ai-driven accounting firm`}</a>{` in NYC. I'm an avid `}<a parentName="p" {...{
        "href": "https://stackoverflow.com/users/3297971/skip-jack"
      }}>{`programmer`}</a>{`
and `}<a parentName="p" {...{
        "href": "https://dribbble.com/skipjack"
      }}>{`designer`}</a>{`. I love challenging `}<a parentName="p" {...{
        "href": "/projects"
      }}>{`projects`}</a>{`, strive for simplicity in
the face of complexity, and enjoy working with teams that are committed to
delivering high quality products.`}</p>
    <p>{`I live with my son Cody and fiance `}<a parentName="p" {...{
        "href": "https://www.linkedin.com/in/alisa-pena-282699a1"
      }}>{`Alisa`}</a>{` in New York. When I'm not on my
computer `}<a parentName="p" {...{
        "href": "https://giphy.com/gifs/q0eh4TU8OMXRu"
      }}>{`hammering away`}</a>{` on some project, we're traveling, snowboarding,
waterskiing, or getting into various other shenanigans. I love a
`}<a parentName="p" {...{
        "href": "https://giphy.com/gifs/kimsconvenience-season-2-cbc-mnu0G0lf1gP61dAlsb"
      }}>{`good time`}</a>{`, but I'm also highly organized and take a lot of pride in my
work. Good software makes people's lives easier and I take that `}<a parentName="p" {...{
        "href": "https://medium.com/@mbostock/what-makes-software-good-943557f8a488"
      }}>{`mission`}</a>{`
seriously.`}</p>
    <p>{`Any code samples, designs, or thoughts on this site or my public repos are
exposed with the hope that others will find them useful. I encourage you to
read on, copy techniques and ideas, and reach out if you have questions. That
said, you `}<em parentName="p">{`may not clone the design or content of this site without written
permission`}</em>{`.`}</p>
    <div style={{
      display: 'flex',
      marginTop: 20
    }}>
  <Social url="https://dribbble.com/skipjack" mdxType="Social" />
  <Social url="https://codepen.io/gvenech" bgColor="#333" mdxType="Social" />
  <Social url="https://github.com/skipjack" mdxType="Social" />
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      